import React from "react";
import Helmet from 'react-helmet';
import SEO from "../../components/SEO";
import Layout from "../../layouts/index";
import Call from "../../components/Call";

const Contact = props => (
  <Layout bodyClass="page-contact">
    <SEO title="Contact - I want Grinding/Abrasive-Polishing Materials" />
    <Helmet>
        <meta
          name="description"
          content="Contact Seaman Industries, Manufacturers of Optical Goods, Barrel-Drum Materials, Grinding-Polishing Materials, as well as media and related supplies"
        />
      </Helmet>
    <div className="intro intro-small">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Contact</h1>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <Call button={false} />
        </div>

        <div className="col-12">
          <h4 className="mt-5">We answer all your queries</h4>
          <ul className="list mb-0">
            <li>Have any questions related to any ongoing process?</li>
            <li>
              Do you have ideas for a new product, and need
              suggestions/consultation on which process and products to use?
            </li>
            <li>
              Are you a new buyer, and need to know about our products and
              process relevant to it?
            </li>
            <li>Do you have any suggestions on how we can improve?</li>
          </ul>
        </div>

        <div className="col-12">
          <h4 className="mt-4">Business Hours</h4>
          <p>Monday to Saturday - 9am to 6pm</p>
        </div>
      </div>
    </div>
  </Layout>
);

export default Contact;
